import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Card, CardBody, Button } from 'reactstrap';
import ReactTable from "react-table";

class UploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            uploadModalOpen: false,
            selectedFile: null,
            fileName: "",
            imageQuality: "",
            isUploading: false, 
        };
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileData = {
                id: Date.now(),
                fileName: file.name,
                quality: this.checkImageQuality(file),
                file: file,
            };
            this.setState((prevState) => ({
                tableData: [...prevState.tableData, fileData],
                selectedFile: file,
                fileName: file.name,
                imageQuality: this.checkImageQuality(file),
            }));
        }
    };

    checkImageQuality = (file) => {
        return file.size > 500000 ? "bad" : "good";
    };

    handleViewImage = (file) => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
    };

    handleDeleteImage = (id) => {
        this.setState((prevState) => ({
            tableData: prevState.tableData.filter(item => item.id !== id),
        }));
    };

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]); // Get the Base64 part
            reader.onerror = (error) => reject(error);
        });
    };

    handleUploadImage = async (fileData) => {
        try {
            console.log("Starting upload process for file:", fileData.fileName);
    
            const base64Image = await this.convertToBase64(fileData.file);
            const payload = {
                organizationUuid: this.props.organizationUuid,
                d64: base64Image,
                filename: fileData.fileName,
                path: "your/path/here", // Set the path accordingly
                fieldName: this.props.uploadprops.fieldName, // Set the fieldName accordingly
                controllId: this.props.uploadprops.controllId,
                arrivalId: this.props.arrivalID,
                Upload:true,
                ImageQuality: this.state.imageQuality,
                comments: "your comments", // Set the comments if needed
            };
    
            console.log("Payload being sent:", payload);
    
            this.setState({ isUploading: true });
    
            // Using fetch instead of axios
            const response = await fetch('https://dev-e4-igit-driverappapi.azurewebsites.net/api/AppRun/GateImagesUploadToBlob', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            console.log("Response status:", response.status);
    
            if (response.ok) {
                const data = await response.json();
                console.log("Response data:", data);
                alert("Image uploaded successfully!");
            } else {
                const errorData = await response.json();
                console.error("Error uploading image:", errorData);
                alert("Failed to upload the image.");
            }
        } catch (error) {
            console.error("Error during upload process:", error);
            alert("Failed to upload the image.");
        } finally {
            this.setState({ isUploading: false });
        }
    };
    

    tableColumns = [
        {
            accessor: "index",
            Header: "#",
            default: true,
            filterable: false,
            sortable: true,
            Cell: (row) => row.index + 1,
        },
        {
            accessor: "fileName",
            Header: "File Name",
            default: true,
            filterable: true,
            sortable: true,
            Cell: (row) => row.original.fileName || "",
        },
        {
            accessor: "quality",
            Header: "Quality",
            default: true,
            filterable: true,
            sortable: true,
            Cell: (row) => row.original.quality || "",
        },
        {
            accessor: "action",
            Header: "Action",
            default: true,
            filterable: false,
            sortable: false,
            Cell: (row) => (
                <div>
                    <i className="fa-regular fa-eye" style={{ cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => this.handleViewImage(row.original.file)}></i>
                    
                    <i className="fa-solid fa-cloud-upload-alt" style={{ cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => this.handleUploadImage(row.original)}></i>
                    
                    <i className="fa-solid fa-trash-can" style={{ cursor: 'pointer' }}
                        onClick={() => this.handleDeleteImage(row.original.id)}></i>
                </div>
            ),
        },
    ];

    render() {
        return (
            <Modal isOpen={this.props.uploadModalOpen} toggle={this.props.toggleModal} size="lg">
                <ModalHeader toggle={this.props.toggleModal}>
                    Upload Details
                </ModalHeader>

                <ModalBody>
                    <Card className="simple-card">
                        <CardBody>
                            <div style={{ marginBottom: '20px' }}>
                                <Input
                                    type="file"
                                    onChange={this.handleFileChange}
                                />
                            </div>
                            <div className="main-table-wrapper">
                                <ReactTable
                                    manual
                                    showPagination={false}
                                    columns={this.tableColumns}
                                    data={this.state.tableData ? this.state.tableData : []}
                                    className="-striped -highlight"
                                    style={{
                                        maxHeight: this.state.tableData.length > 5 ? "400px" : `${this.state.tableData.length * 60}px`,
                                        minHeight: "300px",
                                        overflowY: "auto", // Adds scrolling if the data exceeds the max height
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>

                    <div className="mb-3 mt-2 text-right">
                        <Button
                            className={"float-left"}
                            color="danger"
                            onClick={() => this.props.onClose && this.props.onClose()} 
                        >
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default UploadModal;
