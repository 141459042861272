import React, { Component } from "react";
import * as Yup from "yup";
import Modal from "reactstrap/es/Modal";
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Row,
  ModalBody,
  ModalHeader
} from "reactstrap";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import UploadModal from "./UploadImageModal";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import {
  postArrivalUpdate,
  postArrivalInfoByArrivald,
  getArrivalInfoByArrivald,
  getDataByArrivalID,
  cancelArrival,
  postSaveArrival,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseSpotTypeByLocationUUidAzure,
  carriersByLocationUUid,
  getTaskTypeGate,
  getDepartureTrailerInfo,
  postArrivalTrailerData,
  postArrivalTrailerDataV2,
  putCheckInCheckOutStatusChange,
  checkFlagForCheckInCheckOut,
  UpdateArrivalCheckIn,
  UpdateArrivalComplete,
  UpdateArrivalCheckOut,
  postArrival
} from "../../NetworkHelpers";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {
  getErrorsFromValidationError
} from "../utilities";
import Config from "../../Config";
import "react-image-lightbox/style.css";
import CancelArrival from "./CancelArrival";
import FFFormCheckboxField from "../formik-free/FFFormCheckboxField";
import FFFormAsyncSelectField from "../formik-free/FFFormAsyncSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import FFFormTextAreaField from "../formik-free/FFFormTextAreaField";

class EditGateModal extends Component {

  constructor(props) {
    super(props);
    let activeTabValue = '1';

    // Conditionally set activeTab based on this.props.btnStr

    this.state = {
      trailerOptions: [],
      arrivalOptions: [],
      departureOptions: [],
      attachments: {},
      selectedArrival: {
        firstname: "",
        mobile: "",
        arrivalID: "",
      },
      selectedArrivalInfo: {
        arrivaltasktype: "CHECK-IN ASSET (GATE)",
        departuretasktype: "CHECK-OUT ASSET (GATE)",
        arrivaltasktypename: "",
        departtasktypename: "",
      },
      singleSelectedArrivalInfo: {},
      activeTab: activeTabValue,
      updatePopupState: false,
      uploadModalOpen: false,
      uploadprops: {},
      errors: {},
      loaded: false,
      touched: {},
      isdisabled: false,
      carriers: [],

    };


  }
  componentDidMount() {
    const getCarriers = carriersByLocationUUid(this.props.currentLocation.uuid);

    const inputValue = "";
    getCarriers(inputValue)
      .then((listData) => {
        const carriers = listData.map((item) => ({ value: item.value, uuid: item.uuid }));
        this.setState({ carriers });
      })
      .catch((error) => {
        console.error("Error fetching carriers:", error);
      });

    getTaskTypeGate(
      this.props.currentLocation.uuid,
      this.props.user.company.uuid,
      ({ arrivalOptions, departureOptions }) => {
        console.log("Task types received:", { arrivalOptions, departureOptions });
        this.setState({ arrivalOptions, departureOptions });
      },
      (error) => {
        if (error) {
          console.error(`Server error: ${error.message} (Status Code: ${error.statusCode})`);
        } else {
          console.error("Error fetching task types:", error);
        }
      }
    );

  }
  findDefaultValue = (options, defaultLabel) => {
    const option = options.find(option => option.label === defaultLabel);
    return option ? option.value : null;
  };
  updateTaskTypes = () => {
    const { arrivalOptions, departureOptions, selectedArrivalInfo } = this.state;

    const findDefaultValue = (options, defaultLabel) => {
      const option = options.find(option => option.label === defaultLabel);
      return option ? option.value : null;
    };

    const arrivaltasktypeOption = arrivalOptions.find(option => option.value === selectedArrivalInfo.arrivaltasktype);
    const departuretasktypeOption = departureOptions.find(option => option.value === selectedArrivalInfo.departuretasktype);


    this.setState({
      selectedArrivalInfo: {
        ...selectedArrivalInfo,
        arrivaltasktype: arrivaltasktypeOption
          ? arrivaltasktypeOption.value
          : findDefaultValue(arrivalOptions, "CHECK-IN ASSET (GATE)"),
        arrivaltasktypename: arrivaltasktypeOption
          ? arrivaltasktypeOption.label
          : "CHECK-IN ASSET (GATE)",
        departuretasktype: departuretasktypeOption
          ? departuretasktypeOption.value
          : findDefaultValue(departureOptions, "CHECK-OUT ASSET (GATE)"),
        departtasktypename: departuretasktypeOption
          ? departuretasktypeOption.label
          : "CHECK-OUT ASSET (GATE)",
      },
    });
  };

  fetchArrivalTrailerInfo = (trailerUuid) => {
    this.setState({ loaded: true });
    this.updateSpinner("");

    const payload = {
      organizationUuid: this.props.user.company.uuid,
      arrivalTrailerName: trailerUuid,
    };

    postArrivalTrailerData(
      payload,
      (response) => {
        console.log("Response:", response);

        if (response && response.statusCode === "200") {
          this.setState({ loaded: false });

          const trailerDetails = response.data.trailerDetails ? JSON.parse(response.data.trailerDetails) : {};

          const arrivalAssetType = trailerDetails.arrivalassettype || "";
          const arrivalAssetTypeName = trailerDetails.arrivalassettypename || "";
          const arrivalTrailerNumber = trailerDetails.arrivaltrailernumber || "";

          this.setState((prevState) => ({
            selectedArrivalInfo: {
              ...prevState.selectedArrivalInfo,
              arrivaltrailernumber: arrivalTrailerNumber,
              departuretrailerno: this.state.selectedArrivalInfo.departureplan && this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ? arrivalTrailerNumber : this.state.selectedArrivalInfo.departuretrailerno,
              arrivalassettypename: arrivalAssetTypeName,
              arrivalassettype: arrivalAssetType,
            },
          }));

          console.log("Updated selectedArrivalInfo:", this.state.selectedArrivalInfo);


        } else {
          this.setState({ loaded: false });
          console.error("API returned an invalid status:", response.statusCode);
        }
      },
      (error) => {
        this.setState({ loaded: false });
        console.error("API Failure Response:", error);
      }
    );
  };


  fetchTrailerInfo = (locationUuid, trailerName) => {
    this.setState({ loaded: true });
    this.updateSpinner("");
    const payload = {
      locationUuid: locationUuid,
      trailerName: trailerName,
    };
    getDepartureTrailerInfo(
      payload,
      (response) => {
        console.log("adeem Response:", response);
        if (response && response.statusCode === "200") {
          this.setState({ loaded: false });
          const trailerOptions = this.createDropdownOptions(response.data);
          this.setState({ trailerOptions });
        } else {
          this.setState({ loaded: false });

          console.error("API returned an invalid status:", response.statusCode);
        }
      },
      (error) => {
        this.setState({ loaded: false });

        console.error("API Failure Response:", error);
      }
    );
  }
  createDropdownOptions = (data) => {
    return data.map((trailer) => {
      const { trailerName, shipmentNumber, dwellTime, trailerNumber } = trailer;

      const optionLabel = this.state.selectedArrivalInfo.departurestatus && this.state.selectedArrivalInfo.departurestatus.toLowerCase() === "loaded"
        ? `${trailerNumber}${shipmentNumber ? ' - ' + shipmentNumber : ' - 0'}`
        : this.state.selectedArrivalInfo.departurestatus && this.state.selectedArrivalInfo.departurestatus.toLowerCase() === "empty"
          ? `${trailerNumber} - ${dwellTime}`
          : '';


      return {
        label: optionLabel,
        value: trailerNumber,
      };
    });
  }
  fetchRecord(arrivalID) {
    this.setState({ loaded: true });
    this.updateSpinner("");

    getArrivalInfoByArrivald(arrivalID, (res) => {
      if (res.statusCode === "200") {

        if (this.props.btnStr === "checkout") {
          this.setState({ activeTab: '3' })

        }
        else if (this.props.btnStr === "checkin") {
          this.setState({ activeTab: '2' })

        }
        const departTaskInfo = res.data.departTaskInfo ? res.data.departTaskInfo[0] : {};
        const driverInfo = res.data.driverInfo ? res.data.driverInfo[0] : {};
        const arrivalTaskInfo = res.data.arrivalTaskInfo ? res.data.arrivalTaskInfo[0] : {};
        console.log("driver Jonny", departTaskInfo.departuretrailername)
        if (departTaskInfo.departuretrailername && departTaskInfo.departuretrailername.departuretrailername) {
          this.fetchTrailerInfo(this.props.currentLocation.uuid, departTaskInfo.departuretrailername.departuretrailername);

        }

        const selectedArrivalInfo = {
          locationCode: this.props.selectedArrival.locationCode,
          eventStatusId: this.props.selectedArrival.eventStatusId,
          eventStatus: this.props.selectedArrival.eventStatus,
          nextScreenStatus: this.props.selectedArrival.nextScreenStatus,
          locationName: this.props.selectedArrival.locationCode,
          locationUuid: this.props.selectedArrival.locationUuid,
          locationTimeZone: this.props.selectedArrival.timezone,
          arrivalID: this.props.selectedArrival.arrivalID,
          assignee: this.props.selectedArrival.assignee,
          commandstatus: this.props.selectedArrival.commandstatus,

          // DepartInformation
          departureplan: arrivalTaskInfo.arrivalplan &&
            arrivalTaskInfo.arrivalplan.toLowerCase() == "live" ?
            "live" :
            arrivalTaskInfo.arrivalplan.toLowerCase() == "drop" ?
              "Hook" :
              departTaskInfo.departureplan || "",
          departuretrailerno: (departTaskInfo.departuretrailerno && departTaskInfo.departuretrailerno.departuretrailerno) || "",
          departuretrailernoLable: (departTaskInfo.departuretrailerno && departTaskInfo.departuretrailerno.departuretrailerno) || "",
          departuretrailername: (departTaskInfo.departuretrailername && departTaskInfo.departuretrailername.departuretrailername) || "",
          departurestatus: departTaskInfo.departurestatus || "",
          departuresealno: (departTaskInfo.departuresealno && departTaskInfo.departuresealno.departuresealno) || "",
          departspotname: departTaskInfo.departspotname || "",
          departassettypename: departTaskInfo.departassettypename || "",
          departspot: departTaskInfo.departspot || "",
          departassettype: departTaskInfo.departassettype || "",
          departuretasktype: departTaskInfo.departuretasktype || "",
          departshipmentnumber: (departTaskInfo.departshipmentnumber && departTaskInfo.departshipmentnumber.departshipmentnumber) || "",
          departcomment: (departTaskInfo.departcomment && departTaskInfo.departcomment.departcomment) || "",
          //Arrival info
          arrivalplan: arrivalTaskInfo.arrivalplan || "",
          arrivaltrailernumber: (arrivalTaskInfo.arrivaltrailernumber && arrivalTaskInfo.arrivaltrailernumber.arrivaltrailernumber) || "",
          arrivaltrailername: (arrivalTaskInfo.arrivaltrailername && arrivalTaskInfo.arrivaltrailername.arrivaltrailername) || "",
          arrivalstatus: arrivalTaskInfo.arrivalstatus || "",
          arrivalsealno: (arrivalTaskInfo.arrivalsealno && arrivalTaskInfo.arrivalsealno.arrivalsealno) || "",
          arrivaltasktype: arrivalTaskInfo.arrivaltasktype || "",
          arrivalpartner: arrivalTaskInfo.arrivalpartner || "",
          arrivalspotname: arrivalTaskInfo.arrivalspotname || "",
          arrivalassettypename: arrivalTaskInfo.arrivalassettypename || "",
          arrivalspot: arrivalTaskInfo.arrivalspot || "",
          arrivalassettype: arrivalTaskInfo.arrivalassettype || "",
          arrivalshipmentnumber: (arrivalTaskInfo.arrivalshipmentnumber && arrivalTaskInfo.arrivalshipmentnumber.arrivalshipmentnumber) || "",
          arrivalcomment: (arrivalTaskInfo.arrivalcomment && arrivalTaskInfo.arrivalcomment.arrivalcomment) || "",



          // driver info
          lastname: (driverInfo.lastnameControllMapping && driverInfo.lastnameControllMapping.lastname) || "",
          firstname: (driverInfo.firstnameControllMapping && driverInfo.firstnameControllMapping.firstname) || "",
          trailername: (driverInfo.trailernameControllMapping && driverInfo.trailernameControllMapping.trailername) || "",
          trucknumber: (driverInfo.trucknumberControllMapping && driverInfo.trucknumberControllMapping.trucknumber) || "",
          eta: driverInfo.eta || "",

          // Custom fields
          customFieldList: res.data.customFields,
        };
        const attachments = {}
        if (arrivalTaskInfo) {
          // Check arrivaltrailernumber

          if (arrivalTaskInfo.arrivaltrailernumber && arrivalTaskInfo.arrivaltrailernumber.attachmentEnabled) {
            attachments.arrivaltrailernumber = {
              fieldName: 'arrivaltrailernumber',
              controllId: arrivalTaskInfo.arrivaltrailernumber.controllId,
              attachmentEnabled: true
            };
          }

          if (arrivalTaskInfo.arrivaltrailername && arrivalTaskInfo.arrivaltrailername.controllId) {
            attachments.arrivaltrailername = {
              fieldName: 'arrivaltrailername',
              controllId: arrivalTaskInfo.arrivaltrailername.controllId,
              attachmentEnabled: false
            };
          }

          if (arrivalTaskInfo.arrivalsealno && arrivalTaskInfo.arrivalsealno.attachmentEnabled) {
            attachments.arrivalsealno = {
              fieldName: 'arrivalsealno',
              controllId: arrivalTaskInfo.arrivalsealno.controllId,
              attachmentEnabled: true
            };
          }

          if (arrivalTaskInfo.arrivalplan) {
            attachments.arrivalplan = {
              fieldName: 'arrivalplan',
              controllId: 0,
              attachmentEnabled: false
            };
          }

          if (arrivalTaskInfo.arrivalcomment && arrivalTaskInfo.arrivalcomment.attachmentEnabled) {
            attachments.arrivalcomment = {
              fieldName: 'arrivalcomment',
              controllId: arrivalTaskInfo.arrivalcomment.controllId,
              attachmentEnabled: true
            };
          }


          // Check other fields as needed...
          if (departTaskInfo.departuretrailerno && departTaskInfo.departuretrailerno.attachmentEnabled) {
            attachments.departuretrailerno = {
              fieldName: 'departuretrailerno',
              controllId: departTaskInfo.departuretrailerno.controllId,
              attachmentEnabled: true
            };
          }

          if (departTaskInfo.departuretrailername && departTaskInfo.departuretrailername.controllId) {
            attachments.departuretrailername = {
              fieldName: 'departuretrailername',
              controllId: departTaskInfo.departuretrailername.controllId,
              attachmentEnabled: false
            };
          }

          if (departTaskInfo.departuresealno && departTaskInfo.departuresealno.attachmentEnabled) {
            attachments.departuresealno = {
              fieldName: 'departuresealno',
              controllId: departTaskInfo.departuresealno.controllId,
              attachmentEnabled: true
            };
          }

          if (departTaskInfo.departureplan) {
            attachments.departureplan = {
              fieldName: 'departureplan',
              controllId: 0,
              attachmentEnabled: false
            };
          }

          if (departTaskInfo.departcomment && departTaskInfo.departcomment.attachmentEnabled) {
            attachments.departcomment = {
              fieldName: 'departcomment',
              controllId: departTaskInfo.departcomment.controllId,
              attachmentEnabled: true
            };
          }

        }
        console.log("attachment: ", attachments);

        this.setState({
          selectedArrivalInfo,
          attachments,
          uploadprops: {
            ...this.state.uploadprops,
            arrivalID: arrivalID,
          },
          loaded: false,
        }, () => {
          if (this.state.selectedArrivalInfo.departureplan === 'hook') {
            const { selectedArrivalInfo } = this.state;

            if (selectedArrivalInfo.departuretrailername) {
              this.fetchTrailerInfo(this.props.currentLocation.uuid, selectedArrivalInfo.departuretrailername);
            }
          }

          // Always call updateTaskTypes
          this.updateTaskTypes();
        });

      }
    }, (err) => {
      showError(err);
    });
  }


  componentDidUpdate() { console.log("state update1", this.state.selectedArrivalInfo, this.state.trailerOptions) }


  componentWillUpdate(previousProps, prevState, snapshot) {
    if (previousProps.open && this.state.updatePopupState === false && prevState.updatePopupState !== true) {
      this.setState({
        selectedArrival: previousProps.selectedArrival,
        updatePopupState: true,

        currentLocation: previousProps.currentLocation
      });

      this.fetchRecord(previousProps.selectedArrival.arrivalID);

    }



  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    const NumberRegex = /^[0-9\b]+$/;

    let validationRules = {};
    if (this.props.btnStr == "Save & Arrive") {
      validationRules = {
        arrivaltrailername: Yup.string().nullable().required("Trailer Name is Required"),
        arrivaltrailernumber: Yup.string().nullable().required("Trailer Name is Required"),
        arrivalassettypename: Yup.string().nullable().required("Trailer Type is Required"),
        arrivalspotname: Yup.string().nullable().required("Spot is  Required"),
      }
    } else if (this.props.btnStr == "checkout") {
      validationRules = {
        departpartner: Yup.string().nullable().required("Trailer Name is Required"),
        departuretrailerno: Yup.string().nullable().required("Trailer Number is  Required"),
        departassettype: Yup.string().nullable().required("Trailer Type is Required"),
        departspot: Yup.string().nullable().required("Spot is  Required")
      };
    }
    console.log(this.props.btnStr)

    // }

    return Yup.object().shape(validationRules);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  capitalizeFirstLetter = (string) => {
    if (!string) return '';
    const lowerCaseString = string.toLowerCase();
    return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
  };
  handleSubmit = (e) => {
    let errors = this.validate(this.validationSchema.bind(this))(this.state.selectedArrivalInfo);
    let errorsIsEmpty =
      Object.keys(errors).length === 0 &&
      errors.constructor === Object;
    if (this.state.selectedArrivalInfo.arrivalassettypename && this.state.selectedArrivalInfo.arrivalpartner) {
      this.setState({ loaded: true });

      this.updateSpinner("Updating ...");


      this.onSubmit("save");
    }
    else {
      if (!errorsIsEmpty || this.state.errors.name) {
        let t = { ...this.state.touched };
        t.arrivalAssetType = true;
        this.setState({ touched: t, errors: errors });
        e.preventDefault();
        return;
      }
    }



  }



  render() {
    console.log(`Render`)
    return (
      <div>

        <CancelArrival
          user={this.props.user}
          open={this.state.isOpenCancel}
          arrivalID={this.state.selectedArrivalInfo.arrivalID}
          TaskAPI={this.props.currentLocation.enableGateTask}
          onSave={(arrival) => {
            // let s = arrival;
            cancelArrival(arrival, () => {
              this.setState({ isOpenCancel: false });
              this.closePopup();
              this.props.tableRef.current.fetchData();
            }, () => {
            });
          }}
          onClose={() => this.setState({ isOpenCancel: false })}
        />
        <UploadModal
          uploadModalOpen={this.state.uploadModalOpen}
          uploadprops={this.state.uploadprops}
          organizationUuid={this.props.user.company.uuid}
          arrivalID={this.state.selectedArrivalInfo.arrivalID}
          onClose={() => this.setState({ uploadModalOpen: false })}

        />
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.state.loaded} message={this.spinner}>
            <ModalHeader>
              <h1>Edit Gate (Arrival ID: {this.state.selectedArrivalInfo.arrivalID})</h1>
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  let s = { ...this.state.taskUnderEdit };
                  // let errors = this.validate(this.validationSchema.bind(this))(
                  //   this.state.taskUnderEdit
                  // );
                  // let closeCheck = this.state.closeCheck;
                  // let errorsIsEmpty =
                  //   Object.keys(errors).length === 0 &&
                  //   errors.constructor === Object;
                  // if (!errorsIsEmpty || this.state.errors.name) {
                  //   let t = { ...this.state.touched };

                  //   this.setState({ touched: t, errors: errors });
                  //   e.preventDefault();
                  //   return;
                  // }

                  //  this.setState({
                  //   loaded: true,
                  // });
                  this.onSubmit();
                  e.preventDefault();
                }}
              >
                <Card className="simple-card">
                  <CardBody>
                    <div>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '1' ? "active" : ""}
                            onClick={() => { this.toggle('1'); }}
                          >
                            Driver Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '2' ? "active" : ""}
                            onClick={() => { this.toggle('2'); }}
                          >
                            Arrival
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '3' ? "active" : ""}
                            onClick={() => { this.toggle('3'); }}
                          >
                            Departure
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '4' ? "active" : ""}
                            onClick={() => { this.toggle('4'); }}
                          >
                            Custom Fields
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <FFFormTextField
                            fieldname="firstname"
                            label="First Name"
                            // disabled={this.state.selectedArrival.arrivalstatus == "bobtail" ? true : false}
                            placeholder="Enter First Name"
                            icon="fa-user"
                            value={
                              this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.firstname
                                : ''
                            }
                            // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.selectedArrivalInfo);
                            //   let touched = { ...this.state.touched };
                            //   touched.arrivaltrailernumber = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.arrivaltrailernumber}
                            // touched={this.state.touched.arrivaltrailernumber}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              // if (v) {
                              s.firstname = v;
                              this.setState({ selectedArrivalInfo: s });
                              // }
                            }}
                          />
                          <FFFormTextField
                            fieldname="lastname"
                            label="Last Name"
                            // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                            placeholder="Enter Last Name"
                            icon="fa-user"
                            value={
                              this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.lastname
                                : ''
                            }
                            // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.selectedArrivalInfo);
                            //   let touched = { ...this.state.touched };
                            //   touched.arrivaltrailernumber = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.arrivaltrailernumber}
                            // touched={this.state.touched.arrivaltrailernumber}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              // if (v) {
                              s.lastname = v;
                              this.setState({ selectedArrivalInfo: s });
                              // }
                            }}
                          />
                          <FFFormTextField
                            fieldname="trailername"
                            label="Driver Carrier Name"
                            // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                            placeholder="Enter Carrier Name"
                            icon="fa-keynote"
                            value={
                              this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.trailername
                                : ''
                            }
                            // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.selectedArrivalInfo);
                            //   let touched = { ...this.state.touched };
                            //   touched.arrivaltrailernumber = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.arrivaltrailernumber}
                            // touched={this.state.touched.arrivaltrailernumber}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              // if (v) {
                              s.trailername = v;
                              this.setState({ selectedArrivalInfo: s });
                              // }
                            }}
                          />
                          <FFFormTextField
                            fieldname="trucknumber"
                            label="Truck#"
                            // disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true : false}
                            placeholder="Enter Truck Number"
                            icon="fa-truck"
                            value={
                              this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.trucknumber
                                : ''
                            }
                            // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.selectedArrivalInfo);
                            //   let touched = { ...this.state.touched };
                            //   touched.arrivaltrailernumber = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.arrivaltrailernumber}
                            // touched={this.state.touched.arrivaltrailernumber}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              // if (v) {
                              s.trucknumber = v;
                              this.setState({ selectedArrivalInfo: s });
                              // }
                            }}
                          />
                          {/* <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"ETA"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                
                                placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.eta &&
                                  "is-invalid"
                                }
                                selected={this.state.selectedArrivalInfo.eta}
                                onChange={(date) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.eta = date;
                                  
                                    this.setState({ selectedArrivalInfo: s });
                                  
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm:ss a"
                                showTimeInput
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors &&
                                  this.state.errors.eta}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup> */}
                          <FFFormTextField
                            fieldname="eta"
                            label="ETA"
                            disabled={true}
                            placeholder="Enter ETA"
                            icon="fa-tag"
                            value={
                              this.state.selectedArrivalInfo ? this.state.selectedArrivalInfo.eta
                                : ''
                            }
                            // required={this.props.btnStr === 'Save & Arrive' ? true : false}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.selectedArrivalInfo);
                            //   let touched = { ...this.state.touched };
                            //   touched.arrivaltrailernumber = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.arrivaltrailernumber}
                            // touched={this.state.touched.arrivaltrailernumber}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              // if (v) {
                              s.eta = v;
                              this.setState({ selectedArrivalInfo: s });
                              // }
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <h3>Arrival / Inbound</h3>
                          <FFFormSelectField
                            fieldname="arrivalstatus"
                            label="Trailer Status"// trailer status
                            disabled={
                              this.props.btnStr == 'checkout' ? true : false}
                            placeholder="Enter Trailer Status"
                            icon="fa-truck"
                            value={
                              this.state.selectedArrivalInfo.arrivalstatus
                                ? this.capitalizeFirstLetter(this.state.selectedArrivalInfo.arrivalstatus)
                                : ""
                            }
                            required={false}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.selectedArrivalInfo);
                              let touched = { ...this.state.touched };
                              touched.arrivalstatus = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            options={["Loaded", "Empty", "Bobtail"]}

                            error={this.state.errors.arrivalstatus}
                            touched={this.state.touched.arrivalstatus}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              let value = v.value.toLowerCase();
                              s.arrivalstatus = value;
                              this.setState({ selectedArrivalInfo: s });
                            }}
                          />
                          {this.state.selectedArrivalInfo.arrivalstatus !== "bobtail" && (
                            <>
                              <FFFormSelectField
                                defaultOptions={true}
                                fieldname="arrivalplan"
                                label="Plan Type"//plan type
                                icon="fa-warehouse"
                                value={this.capitalizeFirstLetter(this.state.selectedArrivalInfo.arrivalplan)}
                                required={false}
                                isClearable={false}
                                options={["Live", "Drop"]}
                                placeholder="Select the Plan Type"
                                hideEmpty={true}
                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.arrivalplan = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.arrivalplan}
                                touched={this.state.touched.arrivalplan}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  let value = v.value.toLowerCase();
                                  if (value == "live") {
                                    s.departureplan = "live"
                                  }
                                  else if (value == "drop") {
                                    s.departureplan = "hook"
                                    s.departuretrailerno = ""
                                    s.departuretrailername = ""


                                  }
                                  s.arrivalplan = value;
                                  this.setState({ selectedArrivalInfo: s });
                                }
                                }
                              />
                              <FFFormSelectField
                                defaultOptions={true}
                                fieldname="arrivaltasktype"
                                label="Task Type"
                                icon="fa-warehouse"
                                value={
                                  this.state.selectedArrivalInfo.arrivaltasktype
                                    ? this.state.selectedArrivalInfo.arrivaltasktypename
                                    : "CHECK-IN ASSET (GATE)"
                                }
                                required={false}
                                isClearable={false}
                                options={this.state.arrivalOptions}
                                placeholder="Select the Task Type"
                                hideEmpty={true}
                                isDisabled={this.props.btnStr === 'checkout'}
                                onBlur={() => {
                                  let errors = this.validate(this.validationSchema.bind(this))(
                                    this.state.selectedArrivalInfo
                                  );
                                  let touched = { ...this.state.touched };
                                  touched.arrivaltasktype = true;
                                  this.setState({ errors, touched });
                                }}
                                error={this.state.errors.arrivaltasktype}
                                touched={this.state.touched.arrivaltasktype}
                                onChange={(selectedOption) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.arrivaltasktype = selectedOption.value;
                                  s.arrivaltasktypename = selectedOption.label;

                                  this.setState({ selectedArrivalInfo: s });
                                }}
                              />

                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormAsyncSelectField
                                    defaultOptions={true}
                                    fieldname="arrivaltrailername"
                                    label="Trailer Name"
                                    icon="fa-truck"
                                    value={this.state.selectedArrivalInfo.arrivaltrailername}
                                    required={
                                      this.props.btnStr != 'Save & Arrive' || this.state.selectedArrivalInfo.arrivalplan == 'Bobtail'
                                        ? false
                                        : true
                                    }
                                    isClearable={false}
                                    placeholder="Enter Trailer Name"
                                    hideEmpty={true}
                                    isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                    loadOptions={carriersByLocationUUid(this.props.currentLocation.uuid)}
                                    onBlur={() => {
                                      let errors = this.validate(this.validationSchema.bind(this))(
                                        this.state.selectedArrivalInfo
                                      );
                                      let touched = { ...this.state.touched };
                                      touched.arrivaltrailername = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.arrivaltrailername}
                                    touched={this.state.touched.arrivaltrailername}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };

                                      if (v) {
                                        s.arrivalPartner = v.uuid;
                                        s.arrivaltrailername = v.name;
                                        this.fetchArrivalTrailerInfo(s.arrivalPartner);
                                        if (s.departureplan && s.departureplan == "live") {
                                          s.departuretrailername = s.arrivaltrailername

                                        }
                                        if (s.arrivalplan.toLowerCase() == "drop" && s.departureplan.toLowerCase() == "hook") {
                                          s.departuretrailername = s.arrivaltrailername

                                        }

                                      }

                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.arrivaltrailername && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.arrivaltrailername.controllId,
                                          fieldName: attachments.arrivaltrailername.name,
                                        },
                                      });
                                    }}>

                                  </i>
                                )} */}
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="arrivaltrailernumber"
                                    label="Trailer Number"
                                    disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                      this.props.btnStr == 'checkout' ? true : false}
                                    placeholder="Enter Trailer Number"
                                    icon="fa-truck"
                                    value={
                                      this.state.selectedArrivalInfo.arrivaltrailernumber
                                        ? this.state.selectedArrivalInfo.arrivaltrailernumber
                                        : ""
                                    }
                                    required={this.props.btnStr != 'Save & Arrive' || this.state.selectedArrivalInfo.arrivalplan == "Bobtail" ? false : true}

                                    isClearable={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.arrivaltrailernumber = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.arrivaltrailernumber}
                                    touched={this.state.touched.arrivaltrailernumber}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      // if (v) {
                                      s.arrivaltrailernumber = v;
                                      if (s.departureplan && s.departureplan == "live") {
                                        s.departuretrailerno = s.arrivaltrailernumber

                                      }
                                      this.setState({ selectedArrivalInfo: s });
                                      // }
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.arrivaltrailernumber && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.arrivaltrailernumber.controllId,
                                          fieldName: attachments.arrivaltrailernumber.fieldname,
                                        },
                                      });
                                    }}
                                  ></i>
                                )} */}
                              </div>
                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="arrivalassettypename"
                                label="Trailer Type"
                                icon="fa-truck"
                                value={this.state.selectedArrivalInfo.arrivalassettypename}
                                required={this.props.btnStr != 'Save & Arrive' || this.state.selectedArrivalInfo.arrivalplan == "Bobtail" ? false : true}

                                isClearable={false}
                                placeholder="Select the Trailer Type"
                                hideEmpty={true}
                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                loadOptions={promiseEZTrackEquipmentTypeByPrefix(
                                  this.props.currentLocation.uuid
                                )}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.arrivalassettype = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.arrivalassettype}
                                touched={this.state.touched.arrivalassettype}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  let t = { ...this.state.touched };

                                  if (v) {
                                    t.arrivalassettype = true;
                                    s.arrivalassettype = v.uuid;
                                    s.arrivalassettypename = v.name;
                                  }

                                  this.setState({ selectedArrivalInfo: s, touched: t });
                                }
                                }
                              />

                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="arrivalspotname"
                                label="Move To Spot Spot-Number"
                                icon="fa-spot"

                                value={this.state.selectedArrivalInfo.arrivalspotname}
                                required={this.props.btnStr != 'Save & Arrive' || this.state.selectedArrivalInfo.arrivalplan == "Bobtail" ? false : true}

                                isClearable={false}
                                placeholder="Select the Spot"
                                hideEmpty={true}
                                isDisabled={this.props.btnStr == 'checkout' ? true : false}
                                loadOptions={promiseSpotTypeByLocationUUidAzure(
                                  this.props.currentLocation.uuid
                                )}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.arrivalspotname = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.arrivalspotname}
                                touched={this.state.touched.arrivalspotname}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };

                                  if (v) {
                                    s.arrivalspot = v.uuid;
                                    s.arrivalspotname = v.name;
                                  }

                                  this.setState({ selectedArrivalInfo: s });
                                }
                                }
                              />
                              {/* <FFFormTextField
                                fieldname="arrivalreference"
                                label="Ref#"
                                disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                  this.props.btnStr == 'checkout' ? true : false}
                                placeholder="Enter Reference Number"
                                icon="fa-tag"
                                value={
                                  this.state.selectedArrivalInfo.arrivalreference
                                    ? this.state.selectedArrivalInfo.arrivalreference
                                    : ""
                                }
                                required={false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.arrivalreference = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.arrivalreference}
                                touched={this.state.touched.arrivalreference}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.arrivalreference = v;
                                  this.setState({ selectedArrivalInfo: s });
                                }}
                              /> */}

                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="arrivalsealno"
                                    label="Seal Number"
                                    disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                      this.props.btnStr == 'checkout' ? true : false}
                                    placeholder="Enter Seal Number"
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.arrivalsealno && this.state.selectedArrivalInfo.arrivalsealno!="null"
                                        ? this.state.selectedArrivalInfo.arrivalsealno
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.arrivalsealno = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.arrivalsealno}
                                    touched={this.state.touched.arrivalsealno}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.arrivalsealno = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.arrivalsealno && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.arrivalsealno.controllId,
                                          fieldName: attachments.arrivalsealno.fieldName,
                                        },
                                      });
                                    }}
                                  ></i>
                                )} */}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="arrivalshipmentnumber"
                                    label="Arrival Shipment Number"
                                    disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                      this.props.btnStr == 'checkout' ? true : false}
                                    placeholder="Enter Shipment Number"
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.arrivalshipmentnumber &&  this.state.selectedArrivalInfo.arrivalshipmentnumber!=="null"

                                        ? this.state.selectedArrivalInfo.arrivalshipmentnumber
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.arrivalshipmentnumber = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.arrivalshipmentnumber}
                                    touched={this.state.touched.arrivalshipmentnumber}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.arrivalshipmentnumber = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.arrivalshipmentnumber && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.arrivalshipmentnumber.controllId,
                                          fieldName: attachments.arrivalshipmentnumber.fieldName,
                                        },
                                      });
                                    }}                                  ></i>
                                )} */}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="arrivalcomment"
                                    label="Comments"
                                    disabled={this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? true :
                                      this.props.btnStr == 'checkout' ? true : false}
                                    placeholder="Enter Comments..."
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.arrivalcomment &&  this.state.selectedArrivalInfo.arrivalcomment!="null"

                                        ? this.state.selectedArrivalInfo.arrivalcomment
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.arrivalcomment = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.arrivalcomment}
                                    touched={this.state.touched.arrivalcomment}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.arrivalcomment = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.arrivalcomment && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.arrivalcomment.controllId,
                                          fieldName: attachments.arrivalcomment.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="3">
                          <h3>Departure / Outbound</h3>
                          <FFFormSelectField
                            fieldname="departurestatus"
                            label="Trailer Status"
                            //disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                            placeholder="Enter Trailer Status"
                            options={["Loaded", "Empty", "Bobtail"]}
                            icon="fa-truck"
                            value={
                              this.state.selectedArrivalInfo.departurestatus
                                ? this.capitalizeFirstLetter(this.state.selectedArrivalInfo.departurestatus)
                                : ""
                            }

                            required={false}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.selectedArrivalInfo);
                              let touched = { ...this.state.touched };
                              touched.departurestatus = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.departurestatus}
                            touched={this.state.touched.departurestatus}
                            onChange={(v) => {
                              let s = { ...this.state.selectedArrivalInfo };
                              let value = v.value.toLowerCase();
                              if (s.departureplan.toLowerCase() == "hook") {
                                s.departuretrailerno = "";
                                s.departuretrailername = "";
                                if (s.departuretrailernoLable) {
                                  s.departuretrailernoLable = "";
                                }
                              }
                              s.departurestatus = value;

                              this.setState({ selectedArrivalInfo: s });
                            }}
                          />

                          {this.state.selectedArrivalInfo.departurestatus !== "bobtail" && (
                            <>
                              <FFFormSelectField
                                defaultOptions={true}
                                fieldname="departureplan"
                                label="Plan Type"
                                icon="fa-warehouse"

                                value={this.capitalizeFirstLetter(this.state.selectedArrivalInfo.departureplan)}
                                required={false}
                                isClearable={false}
                                placeholder="Select the Plan Type"
                                hideEmpty={true}
                                options={["Live", "Hook"]}

                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departureplan = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.departureplan}
                                touched={this.state.touched.departureplan}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  let value = v.value.toLowerCase();

                                  s.departureplan = value;
                                  if (s.departureplan.toLowerCase() == "hook") {
                                    s.departuretrailerno = "";
                                    s.departuretrailername = "";
                                    if (s.departuretrailernoLable) {
                                      s.departuretrailernoLable = "";
                                    }
                                  }

                                  this.setState({ selectedArrivalInfo: s });
                                }
                                }
                              />
                              <FFFormSelectField
                                defaultOptions={true}
                                fieldname="departuretasktype"
                                label="Task Type"
                                icon="fa-warehouse"
                                value={
                                  this.state.selectedArrivalInfo.departuretasktype
                                    ? this.state.selectedArrivalInfo.departtasktypename
                                    : "CHECK-OUT ASSET (GATE)"
                                }
                                required={false}
                                isClearable={false}
                                options={this.state.departureOptions} // Use departureOptions array with value and label
                                placeholder="Select the Task Type"
                                hideEmpty={true}
                                onBlur={() => {
                                  let errors = this.validate(this.validationSchema.bind(this))(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departuretasktype = true;
                                  this.setState({ errors, touched });
                                }}
                                error={this.state.errors.departuretasktype}
                                touched={this.state.touched.departuretasktype}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.departuretasktype = v.value;
                                  s.departtasktypename = v.label;
                                  this.setState({ selectedArrivalInfo: s });
                                }}
                              />

                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormAsyncSelectField
                                    defaultOptions={true}
                                    fieldname="departpartner"
                                    label="Trailer Name"
                                    icon="fa-truck"
                                    isDisabled={this.state.selectedArrivalInfo.departureplan &&
                                      this.state.selectedArrivalInfo.departureplan.toLowerCase() == "live" ? true : false}

                                    value={this.state.selectedArrivalInfo.departuretrailername}
                                    required={this.props.btnStr != 'checkout' ? false : true}
                                    isClearable={false}
                                    placeholder="Enter Trailer Name"
                                    hideEmpty={true}

                                    loadOptions={carriersByLocationUUid(
                                      this.props.currentLocation.uuid
                                    )}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.departpartner = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.departpartner}
                                    touched={this.state.touched.departpartner}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };

                                      if (v) {
                                        s.departpartner = v.uuid;
                                        s.departuretrailername = v.name;
                                        this.fetchTrailerInfo(this.props.currentLocation.uuid, s.departuretrailername);
                                        s.departuretrailerno = "";

                                        if (s.departuretrailernoLable) {
                                          s.departuretrailernoLable = "";
                                        }

                                      }

                                      this.setState({ selectedArrivalInfo: s });
                                    }
                                    }
                                  />
                                </div>
                                {/* {this.state.attachments.departuretrailername && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.departuretrailername.controllId,
                                          fieldName: attachments.departuretrailername.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  {this.state.selectedArrivalInfo.departureplan &&
                                    this.state.selectedArrivalInfo.departureplan.toLowerCase() === "hook" ? (
                                    <FFFormSelectField
                                      defaultOptions={true}
                                      fieldname="departuretrailerno"
                                      label="Trailer Number"
                                      icon="fa-truck"
                                      options={this.state.trailerOptions}
                                      value={this.state.selectedArrivalInfo.departuretrailernoLable}
                                      required={this.props.btnStr !== 'checkout' ? false : true}
                                      isClearable={false}
                                      placeholder="Enter Trailer Name"
                                      hideEmpty={true}
                                      onBlur={() => {
                                        let errors = this.validate(this.validationSchema.bind(this))(
                                          this.state.selectedArrivalInfo
                                        );
                                        let touched = { ...this.state.touched };
                                        touched.departuretrailerno = true;
                                        this.setState({ errors: errors, touched: touched });
                                      }}
                                      error={this.state.errors.departuretrailerno}
                                      touched={this.state.touched.departuretrailerno}
                                      onChange={(v) => {
                                        let s = { ...this.state.selectedArrivalInfo };
                                        if (v) {
                                          console.log(v, "depjected");

                                          s.departuretrailerno = v.value;
                                          s.departuretrailernoLable = v.label;
                                        }
                                        this.setState({ selectedArrivalInfo: s });
                                      }}
                                    />
                                  ) : (
                                    <FFFormTextField
                                      fieldname="departuretrailerno"
                                      label="Trailer Number"
                                      disabled={
                                        this.state.selectedArrivalInfo.departureplan &&
                                          this.state.selectedArrivalInfo.departureplan.toLowerCase() === "live"
                                          ? true
                                          : false
                                      }
                                      placeholder="Enter Trailer Number"
                                      isClearable={false}
                                      icon="fa-truck"
                                      value={
                                        this.state.selectedArrivalInfo.departuretrailerno
                                          ? this.state.selectedArrivalInfo.departuretrailerno
                                          : ""
                                      }
                                      required={this.props.btnStr === 'checkout' ? true : false}
                                      onBlur={() => {
                                        let errors = this.validate(this.validationSchema.bind(this))(
                                          this.state.selectedArrivalInfo
                                        );
                                        let touched = { ...this.state.touched };
                                        touched.departuretrailerno = true;
                                        this.setState({ errors: errors, touched: touched });
                                      }}
                                      error={this.state.errors.departuretrailerno}
                                      touched={this.state.touched.departuretrailerno}
                                      onChange={(v) => {
                                        let s = { ...this.state.selectedArrivalInfo };
                                        s.departuretrailerno = v;
                                        this.setState({ selectedArrivalInfo: s });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* {this.state.attachments.departuretrailerno && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.departuretrailerno.controllId,
                                          fieldName: attachments.departuretrailerno.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="departassettype"
                                label="Trailer Type"
                                icon="fa-truck"
                                value={this.state.selectedArrivalInfo.departassettypename}
                                required={this.props.btnStr != 'checkout' ? false : true}
                                isClearable={false}
                                placeholder="Select the Trailer Type"
                                hideEmpty={true}

                                loadOptions={promiseEZTrackEquipmentTypeByPrefix(
                                  this.props.currentLocation.uuid
                                )}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departassettype = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.departassettype}
                                touched={this.state.touched.departassettype}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };

                                  if (v) {
                                    s.departassettype = v.uuid;
                                    s.departassettypename = v.name;
                                  }

                                  this.setState({ selectedArrivalInfo: s });
                                }
                                }
                              />

                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="departspot"
                                label="Move From Zone Spot-Number"
                                icon="fa-tag"

                                value={this.state.selectedArrivalInfo.departspotname}
                                required={this.props.btnStr != 'checkout' ? false : true}
                                isClearable={false}
                                placeholder="Select the Spot"
                                hideEmpty={true}

                                loadOptions={promiseSpotTypeByLocationUUidAzure(
                                  this.props.currentLocation.uuid
                                )}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departspot = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.departspot}
                                touched={this.state.touched.departspot}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };

                                  if (v) {
                                    s.departspot = v.uuid;
                                    s.departspotname = v.name;
                                  }

                                  this.setState({ selectedArrivalInfo: s });
                                }
                                }
                              />

                              {/* <FFFormTextField
                                fieldname="departurereference"
                                label="Ref#"
                                //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                placeholder="Enter Reference Number"
                                icon="fa-tag"
                                value={
                                  this.state.selectedArrivalInfo.departurereference
                                    ? this.state.selectedArrivalInfo.departurereference
                                    : ""
                                }
                                required={false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.selectedArrivalInfo);
                                  let touched = { ...this.state.touched };
                                  touched.departurereference = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.departurereference}
                                touched={this.state.touched.departurereference}
                                onChange={(v) => {
                                  let s = { ...this.state.selectedArrivalInfo };
                                  s.departurereference = v;
                                  this.setState({ selectedArrivalInfo: s });
                                }}
                              /> */}
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="departuresealno"
                                    label="Seal#"
                                    //     disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                    placeholder="Enter Seal Number"
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.departuresealno &&  this.state.selectedArrivalInfo.departuresealno!=="null"

                                        ? this.state.selectedArrivalInfo.departuresealno
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.departuresealno = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.departuresealno}
                                    touched={this.state.touched.departuresealno}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.departuresealno = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.departuresealno && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.departuresealno.controllId,
                                          fieldName: attachments.departuresealno.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="departshipmentnumber"
                                    label="Departure Shipment Number"
                                    //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                    placeholder="Enter Shipment Number"
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.departshipmentnumber&&this.state.selectedArrivalInfo.departshipmentnumber!="null"
                                        ? this.state.selectedArrivalInfo.departshipmentnumber
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.departurereference = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.departshipmentnumber}
                                    touched={this.state.touched.departshipmentnumber}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.departshipmentnumber = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.departshipmentnumber && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.departshipmentnumber.controllId,
                                          fieldName: attachments.departshipmentnumber.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FFFormTextField
                                    fieldname="departcomment"
                                    label="Comments"
                                    //   disabled={this.state.selectedArrivalInfo.departurestatus == "bobtail" ? true : false}
                                    placeholder="Enter Comments..."
                                    icon="fa-tag"
                                    value={
                                      this.state.selectedArrivalInfo.departcomment && this.state.selectedArrivalInfo.departcomment!="null"
                                        ? this.state.selectedArrivalInfo.departcomment
                                        : ""
                                    }
                                    required={false}
                                    onBlur={() => {
                                      let errors = this.validate(
                                        this.validationSchema.bind(this)
                                      )(this.state.selectedArrivalInfo);
                                      let touched = { ...this.state.touched };
                                      touched.departurereference = true;
                                      this.setState({ errors: errors, touched: touched });
                                    }}
                                    error={this.state.errors.departcomment}
                                    touched={this.state.touched.departcomment}
                                    onChange={(v) => {
                                      let s = { ...this.state.selectedArrivalInfo };
                                      s.departcomment = v;
                                      this.setState({ selectedArrivalInfo: s });
                                    }}
                                  />
                                </div>
                                {/* {this.state.attachments.departcomment && (
                                  <i
                                    className="fa-solid fa-upload"
                                    title="Upload"
                                    style={{ marginLeft: '10px', fontSize: '1.5em', cursor: 'pointer' }}
                                    onClick={() => {
                                      const { attachments } = this.state;

                                      this.setState({
                                        uploadModalOpen: true,
                                        uploadprops: {
                                          ...this.state.uploadprops,
                                          controllId: attachments.departcomment.controllId,
                                          fieldName: attachments.departcomment.fieldName,
                                        },
                                      });
                                    }}                                             ></i>
                                )} */}
                              </div>
                            </>
                          )
                          }
                        </TabPane>
                        <TabPane tabId="4">
                          {this.state.selectedArrivalInfo && this.state.selectedArrivalInfo.customFieldList && this.state.selectedArrivalInfo.customFieldList.map((cfl, index) => {
                            if (cfl.type == "string") {
                              return (
                                <FFFormTextField
                                  label={cfl.name}
                                  placeholder=""
                                  icon="fa-tag"
                                  key={index}
                                  value={
                                    cfl.value
                                  }
                                  required={false}

                                  onChange={(v) => {
                                    let s = { ...this.state.selectedArrivalInfo };
                                    s.customFieldList[index].value = v;
                                    this.setState({ selectedArrivalInfo: s });
                                  }}
                                />
                              )
                            }
                            if (cfl.type == "bool" && cfl.name == "Discount Percentage") {
                              return (
                                <FFFormCheckboxField
                                  label={cfl.name}
                                  placeholder=""
                                  icon="fa-power-off"
                                  value={
                                    cfl.isEnable
                                  }
                                  onChange={(v) => {
                                    let s = { ...this.state.selectedArrivalInfo };
                                    s.customFieldList[index].isEnable = v;
                                    this.setState({ selectedArrivalInfo: s });
                                  }}
                                />
                              )
                            }
                            if (cfl.type == "bool") {
                              return (
                                <FFFormCheckboxField
                                  label={cfl.name}
                                  placeholder=""
                                  icon="fa-power-off"
                                  value={
                                    cfl.value
                                  }
                                  onChange={(v) => {
                                    let s = { ...this.state.selectedArrivalInfo };
                                    s.customFieldList[index].value = v;
                                    this.setState({ selectedArrivalInfo: s });
                                  }}
                                />
                              )
                            }
                            if (cfl.type == "int") {
                              return (
                                <FFFormTextField
                                  label={cfl.name}
                                  placeholder=""
                                  numbersOnly={true}
                                  icon="fa-tag"
                                  key={index}
                                  value={
                                    cfl.value
                                  }
                                  required={false}

                                  onChange={(v) => {
                                    let s = { ...this.state.selectedArrivalInfo };
                                    s.customFieldList[index].value = v;
                                    this.setState({ selectedArrivalInfo: s });
                                  }}
                                />
                              )
                            }
                            if (cfl.type == "datetime") {
                              return (
                                <FormGroup>
                                  <Row>
                                    <Col lg="2" md="3" sm="4">
                                      <Label>
                                        {cfl.name + " (" +
                                          this.timezoneShortCode(
                                            this.props.currentLocation &&
                                            this.props.currentLocation.timezone,
                                            new Date()
                                          ) +
                                          ")"}
                                      </Label>
                                    </Col>
                                    <Col className={"task-form"} lg="10" md="9" sm="8">
                                      <DatePicker
                                        placeholderText={"MM/DD/YYYY HH:MM AP"}
                                        selected={cfl.value}
                                        onChange={(v) => {
                                          let s = { ...this.state.selectedArrivalInfo };
                                          s.customFieldList[index].value = v;
                                          this.setState({ selectedArrivalInfo: s });
                                        }}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm a"
                                        showTimeInput
                                        isClearable
                                        shouldCloseOnSelect={false}
                                      />

                                    </Col>
                                  </Row>
                                </FormGroup>
                              )
                            }

                          })}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
                <div className="mb-3 text-right">
                  <Button

                    className={"float-left"}
                    color="danger"
                    onClick={() => this.setState({ isOpenCancel: true, arrivalID: this.state.selectedArrivalInfo.arrivalID })}
                  >
                    Cancel Task
                  </Button>{" "}

                  &nbsp;&nbsp;
                  <Button
                    color="danger"
                    type="button"
                    onClick={() =>
                      this.closePopup()
                    }

                  >
                    Close
                  </Button>
                  &nbsp;&nbsp;
                  {
                    !this.props.btnStr &&
                    <>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={() => {
                          if (this.state.selectedArrivalInfo.departuretrailerno) {
                            this.onSubmit('save')

                          }
                          else {
                            showInfo("Trailer Number is Empty");
                          }
                        }
                        }

                      >

                        Save
                      </Button>
                    </>
                  }
                  {
                    this.props.btnStr === 'checkin' &&
                    <>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.onSubmit('btnStr')
                        }
                        }

                      >

                        Save
                      </Button>
                    </>
                  }

                  {
                    this.props.btnStr === 'checkout' &&
                    <>
                      <Button
                        disabled={
                          (
                            this.state.selectedArrivalInfo.departuretrailername &&
                            this.state.selectedArrivalInfo.departspotname &&
                            this.state.selectedArrivalInfo.departassettypename
                            && this.state.selectedArrivalInfo.departuretrailerno
                          ) || this.state.selectedArrivalInfo.departurestatus == "bobtail" ? false : true
                        }
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.onSubmit('btnStr')
                        }
                        }
                      >
                        Save
                      </Button>
                      {this.state.selectedArrivalInfo.departurestatus !== "bobtail" &&
                        !(
                          this.state.selectedArrivalInfo.departuretrailerno &&
                          this.state.selectedArrivalInfo.departuretrailername &&
                          this.state.selectedArrivalInfo.departspotname &&
                          this.state.selectedArrivalInfo.departassettypename
                        ) && (
                          <p style={{ color: "#FF0000" }}>Fill all Depart required fields to enable it</p>
                        )}
                    </>

                  }

                  {
                    this.props.btnStr === 'Save & Arrive' &&
                    <>
                      <Button
                        disabled={
                          (this.state.selectedArrivalInfo.arrivalassettypename &&
                            this.state.selectedArrivalInfo.arrivaltrailernumber &&
                            this.state.selectedArrivalInfo.arrivaltrailername &&
                            this.state.selectedArrivalInfo.arrivalspotname) || this.state.selectedArrivalInfo.arrivalstatus == "bobtail" ? false : true


                        }
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.onSubmit('btnStrArrive')
                        }
                        }
                      >
                        {this.props.btnStr}
                      </Button>
                      {this.state.selectedArrivalInfo.arrivalstatus !== "bobtail" &&
                        !(
                          this.state.selectedArrivalInfo.arrivalassettypename &&
                          this.state.selectedArrivalInfo.arrivaltrailernumber &&
                          this.state.selectedArrivalInfo.arrivaltrailername &&
                          this.state.selectedArrivalInfo.arrivalspotname
                        ) && (
                          <p style={{ color: "#FF0000" }}>Fill all Arrival required fields to enable it</p>
                        )
                      }
                    </>
                  }
                </div>
              </Form>
            </ModalBody>
            {/* <ModalFooter>
   
        </ModalFooter> */}
          </Loader>
        </Modal>
      </div>

    )
  }
  closePopup() {
    this.setState({ updatePopupState: false, activeTab: '1', errors: {}, touched: {} })

    return this.props.onClose && this.props.onClose();

  }
  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
  onSubmit(str) {
    if (str === undefined)
      return

    let matchedCarrier = this.state.carriers.find(carrier => carrier.value === this.state.selectedArrivalInfo.arrivaltrailername);
    if (matchedCarrier) {
      this.state.selectedArrivalInfo.arrivalpartner = matchedCarrier.uuid;
    }


    let matchedCarrier1 = this.state.carriers.find(carrier => carrier.value === this.state.selectedArrivalInfo.departuretrailername);
    if (matchedCarrier1) {
      this.state.selectedArrivalInfo.departpartner = matchedCarrier1.uuid;
    }
    if (this.props.btnStr == 'Save & Depart') {

      this.state.selectedArrivalInfo.commandstatus = "depart";
    }
    else if (this.props.btnStr == 'checkin') {

      this.state.selectedArrivalInfo.commandstatus = "Check-In Complete";
      this.state.selectedArrivalInfo.eventStatus = "Check-In Complete";
      this.state.selectedArrivalInfo.checkinUsername = this.props.user.firstname + " " + this.props.user.lastname;
      this.state.selectedArrivalInfo.eventStatusId = "5.5";
      this.state.selectedArrivalInfo.arrivaltasktype = this.state.selectedArrivalInfo.arrivaltasktype ? this.state.selectedArrivalInfo.arrivaltasktype : "Check-In ASSET Gate";



    }
    else if (this.props.btnStr == 'checkout') {
      this.state.selectedArrivalInfo.commandstatus = "Check-Out Complete";
      this.state.selectedArrivalInfo.eventStatus = "Check-Out Complete";
      this.state.selectedArrivalInfo.checkOutUsername = this.props.user.firstname + " " + this.props.user.lastname;
      this.state.selectedArrivalInfo.eventStatusId = "7.5";
      this.state.selectedArrivalInfo.departuretasktype = this.state.selectedArrivalInfo.departuretasktype ? this.state.selectedArrivalInfo.departuretasktype : "Check-Out ASSET Gate";


    }
    const selectedArrivalInfo = this.state.selectedArrivalInfo;

    const jsonString = JSON.stringify(selectedArrivalInfo, null, 2);

    const payload = {
      organizationUuid: this.props.user.company.uuid,
      arrivalTrailerName: this.state.selectedArrivalInfo.arrivalPartner,
      trailerDetails: jsonString,
    };
    console.log(payload, "rana done");
    postArrivalTrailerDataV2(
      payload,
      (response) => {
        console.log("adeem Response:", response);
        if (response && response.statusCode === "200") {
          this.setState({ loaded: false });
          const trailerOptions = this.createDropdownOptions(response.data);
          this.setState({ trailerOptions });
        } else {
          this.setState({ loaded: false });

          console.error("API returned an invalid status:", response.statusCode);
        }
      },
      (error) => {
        this.setState({ loaded: false });

        console.error("API Failure Response:", error);
      }
    );

    if (this.props.btnStr && str === 'btnStr') {
      let text;
      text = this.btnStr === 'Save & Arrive' ? "Any Changes on this page will not be reflected on to Drivers Portal, Kindly contact your driver incase if you have made any changes."
        : 'Any Changes on this page will not be reflected on to Drivers Portal, Kindly contact your driver incase if you have made any changes.';
      if (window.confirm(text) == true) {
        this.setState({
          loaded: true,

        })
        this.updateSpinner('Please Wait...')

        postSaveArrival(this.state.selectedArrivalInfo, (res) => {
          let tastResponse = res.taskStatus;
          if (tastResponse != null) {
            if (tastResponse.taskStatusCode == 200) {
              this.setState({
                activeTab: '1', updatePopupState: false, errors: {},
                loaded: false,
                touched: {}
              })

              showInfo(tastResponse.taskStatus);
              res.isArrDepBtnClick = true;
              return this.props.onSave && this.props.onSave(res);

            }
            else if (tastResponse.taskStatusCode == 1005) {
              this.setState({
                activeTab: '1', updatePopupState: false, errors: {},
                loaded: false,
                touched: {}
              })
              res.isArrDepBtnClick = true;
              return this.props.onSave && this.props.onSave(res);

            }
            else if (tastResponse.taskStatusCode == 0) {
              this.setState({
                activeTab: '1', updatePopupState: false, errors: {},
                loaded: false,
                touched: {}
              })
              res.isArrDepBtnClick = true;
              return this.props.onSave && this.props.onSave(res);

            }
            else {
              showInfo(tastResponse.taskStatus);
              this.setState({
                loaded: false,

              })

            }
          }

          //   if (res.taskStatus.taskStatusCode===200 ){


          //     this.setState({
          //       activeTab: '1', updatePopupState: false, errors: {},
          //       loaded: false,
          //       touched: {}
          //     })
          //     res.isArrDepBtnClick = true;
          //     return this.props.onSave && this.props.onSave(res);


          //   }
          //  else if ( res.taskStatus.taskStatusCode===1005 ){


          //     this.setState({
          //       activeTab: '1', updatePopupState: false, errors: {},
          //       loaded: false,
          //       touched: {}
          //     })
          //     res.isArrDepBtnClick = true;
          //     return this.props.onSave && this.props.onSave(res);

          //   }
          //   else
          //   {

          //     this.setState({
          //       loaded: false,

          //     })

          //   }


        }, (err) => {
          this.setState({
            loaded: false,

          })

        });
      }
    } else {
      this.setState({ loaded: true, })
      if (str == "btnStrArrive") {
        console.log("Arrive loading");
        this.state.selectedArrivalInfo.eventStatus = "ARRIVE";
        this.state.selectedArrivalInfo.eventStatusId = "4";
        this.updateSpinner('Please Wait...')
        this.state.selectedArrivalInfo.commandstatus = this.state.selectedArrivalInfo.eventStatus;
        postArrivalInfoByArrivald(this.state.selectedArrivalInfo, (res) => {
          if (res.taskStatus.taskStatusCode === 200) {
            showInfo("Task Updated");
            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);
          }
          else if (res.taskStatus.taskStatusCode === 0) {

            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);


          }
          else if (res.taskStatus.taskStatusCode === 1005) {

            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);


          }
          else {
            showInfo(res.taskStatus.taskStatus);
            this.setState({
              loaded: false,

            })

          }

        }, (err) => {
          this.setState({
            loaded: false,

          })

        });
      }
      else {
        this.state.selectedArrivalInfo.eventStatus = this.state.selectedArrivalInfo.nextScreenStatus;
        this.updateSpinner('Please Wait...')
        this.state.selectedArrivalInfo.commandstatus = this.state.selectedArrivalInfo.eventStatus;
        postArrivalUpdate(this.state.selectedArrivalInfo, (res) => {
          if (res.taskStatus.taskStatusCode === 200) {
            showInfo("Task Updated");
            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);
          }
          else if (res.taskStatus.taskStatusCode === 0) {

            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);


          }
          else if (res.taskStatus.taskStatusCode === 1005) {

            this.setState({
              activeTab: '1', updatePopupState: false, errors: {},
              loaded: false,
              touched: {}
            })
            res.isArrDepBtnClick = false;
            return this.props.onSave && this.props.onSave(res);


          }
          else {
            showInfo(res.taskStatus.taskStatus);
            this.setState({
              loaded: false,

            })

          }

        }, (err) => {
          this.setState({
            loaded: false,

          })

        });
      }

    }
  }
  localDateToUTC(dateISOLocalString) {
    let localLoginTime = moment.utc(dateISOLocalString).utc();
    var returnDate = new Date(localLoginTime.format('MMM-DD-YYYY HH:mm:ss'));
    return returnDate;
  }
}
export default EditGateModal;